import { Grid, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SnackbarActions } from '../../../../store/snackbar'
import { MatricesActions, MatricesSelector } from '../../../../store/TechnologyMatrices'
import { GrantInfo } from './subComponents/GrantInfo'
import { TechnologyMatricesBar } from './subComponents/TechnologyMatricesBar'
import { TechnologyMatricesDoughnut } from './subComponents/TechnologyMatricesDoughnut'

export const TechnologyMetrices: React.FC = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const params = useParams()
  const techId = params.id || ''
  const won = useSelector(MatricesSelector.selectWon)
  const loss = useSelector(MatricesSelector.selectLoss)
  const applied = useSelector(MatricesSelector.getAppliedGrants)

  useEffect(() => {
    const fetchMatrices = async () => {
      try {
        await dispatch(
          MatricesActions.fetchTechnologyMatricesAsync({
            techId: techId,
          }) as any,
        )
      } catch (error: any) {
        dispatch(SnackbarActions.showSnackbar({ message: error.message }))
      }
    }

    if (techId) {
      fetchMatrices()
    }
  }, [])

  return (
    <Grid container spacing={3} px={4}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight={theme.typography.fontWeightBold}
          gutterBottom
          px={2}
          mt={2}
        >
          Matrices
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <GrantInfo won={won} loss={loss} applied={applied} />
      </Grid>
      <Grid item xs={12}>
        <TechnologyMatricesDoughnut />
      </Grid>
      <Grid item xs={12}>
        <TechnologyMatricesBar />
      </Grid>
    </Grid>
  )
}
