import { ArcElement, Chart as ChartJS, ChartOptions, Legend, Tooltip } from 'chart.js'
import { useSelector } from 'react-redux'
import { DoughnutChart } from '../../../../../components/DoughnutChart'
import { MatricesSelector } from '../../../../../store/TechnologyMatrices'

ChartJS.register(ArcElement, Tooltip, Legend)

export const TechnologyMatricesDoughnut = () => {
  const winPercentage = useSelector(MatricesSelector.selectWinPercentage)
  const lossPercentage = useSelector(MatricesSelector.selectLossPercentage)

  const data = [
    {
      label: 'Wins',
      value: winPercentage,
      color: '#00A94F',
      cutout: '60%',
    },
    {
      label: 'Losses',
      value: lossPercentage,
      color: '#F46E6F',
      cutout: '60%',
    },
  ]

  const options: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          padding: 20,
          generateLabels: (chart) => {
            const data = chart.data
            if (data.labels && data.datasets.length) {
              return data.labels.map((label, index) => {
                const dataset: any = data.datasets[0]
                const backgroundColor = dataset.backgroundColor[index] as string
                return {
                  text: `${label} - ${dataset.data[index]}%`,
                  fillStyle: backgroundColor,
                  strokeStyle: backgroundColor,
                  lineWidth: 1,
                  hidden:
                    isNaN(dataset.data[index] as number) || (dataset.data[index] as number) === 0,
                  index: index,
                }
              })
            }
            return []
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.label || ''
            const value = tooltipItem.raw as number
            return `${label}: ${value}%`
          },
        },
      },
    },
    cutout: '60%',
    responsive: true,
    maintainAspectRatio: false,
  }

  const finalData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
      },
    ],
  }

  return <DoughnutChart data={finalData} options={options} title="Wins and Losses chart" />
}
